import React from "react";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";

export default function CustomButton({
  text,
  onClick,
  variant = "contained",
  color = "primary",
  size = "medium",
  sx = {}, // Propiedad sx para estilos de MUI
  style = {}, // Propiedad style para estilos inline
}) {
  return (
    <Button
      variant={variant}
      color={color}
      size={size}
      onClick={onClick}
      sx={sx} // Aplica los estilos usando sx de MUI
      style={style} // Aplica estilos inline si es necesario
    >
      {text}
    </Button>
  );
}

CustomButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(["text", "contained", "outlined"]),
  color: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  sx: PropTypes.object, // Nueva propiedad sx para estilos
  style: PropTypes.object, // Nueva propiedad style para estilos inline
};

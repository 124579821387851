import React, { useContext, useState, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import { CarritoContext } from "../Context/CarritoContext"; // Importamos el contexto del carrito
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ArrowBackIcon from "@mui/icons-material/KeyboardArrowDown"; // Icono de flecha hacia atrás
import "../css/Cart.css"; // Importa tu archivo de CSS donde estará la animación
import RemoveIcon from "@mui/icons-material/RemoveCircleOutline";
import AddIcon from "@mui/icons-material/AddCircleOutline";
import { IconButton, Box, Typography } from "@mui/material";
import FooterCart from "./Footercart";

export default function CartSidebar() {
  // eslint-disable-next-line
  const { carrito, eliminarProducto, vaciarCarrito, setCarrito } =
    useContext(CarritoContext);
  const [open, setOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  // Cuando se carga el componente, se verifica si hay un carrito guardado en el localStorage
  useEffect(() => {
    const carritoGuardado = JSON.parse(localStorage.getItem("carrito")) || [];
    setCarrito(carritoGuardado); // Restaurar carrito desde localStorage
  }, [setCarrito]); // Añadir setCarrito como dependencia
  // Cada vez que el carrito cambia, se actualiza el localStorage
  useEffect(() => {
    localStorage.setItem("carrito", JSON.stringify(carrito));
  }, [carrito]); // Cada vez que cambia el carrito, se guarda en localStorage
  // eslint-disable-next-line
  const handleAddToCart = (producto) => {
    const nuevoCarrito = [...carrito, producto];
    setCarrito(nuevoCarrito);
    localStorage.setItem("carrito", JSON.stringify(nuevoCarrito)); // Guardar carrito actualizado
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleDrawer = (state) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(state);
  };

  const calcularPrecioTotal = () => {
    return carrito
      .reduce(
        (acc, producto) =>
          acc + (Number(producto.productPrice) || 0) * producto.quantity,
        0
      )
      .toFixed(2);
  };

  const handleIncrement = (productId) => {
    const nuevoCarrito = carrito.map((producto) =>
      producto.productId === productId
        ? { ...producto, quantity: producto.quantity + 1 }
        : producto
    );
    setCarrito(nuevoCarrito);
  };

  const handleDecrement = (productId) => {
    const nuevoCarrito = carrito.map((producto) =>
      producto.productId === productId && producto.quantity > 1
        ? { ...producto, quantity: producto.quantity - 1 }
        : producto
    );
    setCarrito(nuevoCarrito);
  };

  return (
    <div>
      {/* Botón para abrir el carrito */}
      <IconButton
        color="primary"
        onClick={toggleDrawer(true)}
        sx={{
          position: "fixed",
          top: "1rem",
          right: isMobile ? "2rem" : "4rem",
          zIndex: 9999,
          backgroundColor: "black",
          color: "white",
          boxShadow: "white",
          padding: isMobile ? "8px" : "10px",
          "&:hover": {
            backgroundColor: "white",
            color: "black",
          },
        }}
      >
        <ShoppingCartIcon />
      </IconButton>

      {/* Drawer para el carrito */}
      <Drawer
        anchor={isMobile ? "bottom" : "right"}
        open={open}
        onClose={toggleDrawer(false)}
        sx={{ zIndex: 9998 }}
      >
        <Box
          sx={{
            width: isMobile ? "100%" : 600,
            padding: 2,
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100vh",
          }}
          role="presentation"
        >
          {/* Contenido del carrito */}
          <Box sx={{ overflowY: "auto", flexGrow: 1 }}>
            {isMobile && (
              <IconButton
                color="primary"
                onClick={toggleDrawer(false)}
                sx={{
                  position: "absolute",
                  top: "-1px",
                  left: "1rem",
                  zIndex: 9999,
                  backgroundColor: "transparent",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
              >
                <ArrowBackIcon />
              </IconButton>
            )}

            <h2>Carrito de compras</h2>

            {carrito.length === 0 ? (
              <p>El carrito está vacío</p>
            ) : (
              <>
                {carrito.map((producto, index) => (
                  <Box key={index} sx={{ marginBottom: 2 }}>
                    <img
                      src={producto.productImage}
                      alt={producto.productName}
                      style={{
                        width: "150px",
                        height: "200px",
                        objectFit: "cover",
                        marginBottom: "10px",
                      }}
                    />
                    <div style={{ marginLeft: "11rem" }}>
                      <div style={{ marginTop: "-15rem" }}>
                        <p>{producto.productName}</p>
                      </div>
                      <p>
                        Color: {producto.baseColor} <br />
                        Bordado: {producto.secondaryColor}
                      </p>

                      <Box display="flex" alignItems="center">
                        <Typography
                          sx={{
                            marginRight: 2,
                            fontFamily: "MiFuete",
                          }}
                        >
                          Cantidad:
                        </Typography>
                        <IconButton
                          onClick={() => handleDecrement(producto.productId)}
                        >
                          <RemoveIcon />
                        </IconButton>
                        <Typography id="quantity">
                          {producto.quantity}
                        </Typography>
                        <IconButton
                          onClick={() => handleIncrement(producto.productId)}
                        >
                          <AddIcon />
                        </IconButton>
                      </Box>
                      <div
                        className="eliminar"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "90%",
                        }}
                      >
                        <Typography
                          variant="body5"
                          color="black"
                          onClick={() => eliminarProducto(producto.productId)}
                          sx={{
                            fontSize: "15px",
                            textDecoration: "underline",
                            cursor: "pointer",
                            fontFamily: "MiFuete",
                            "&:hover": {
                              color: "#b30000",
                            },
                          }}
                        >
                          Eliminar
                        </Typography>

                        <p
                          style={{
                            marginLeft: isMobile ? "2rem" : "",
                            fontFamily: "Arial, sans-serif",
                            fontSize: isMobile ? "15px" : "",
                          }}
                        >
                          {Number(producto.productPrice).toFixed(2)} MXN
                        </p>
                      </div>
                    </div>
                  </Box>
                ))}

                <Box
                  sx={{
                    display: "flex",
                    width: "90%",
                    alignItems: "center",
                    justifyContent: "space-between",
                    position: "absolute",
                    marginTop: isMobile ? "20vh" : "80%",
                    textAlign: "center",
                  }}
                >
                  <h4>Total del carrito:</h4>
                  <p style={{ fontFamily: "Arial, sans-serif" }}>
                    {calcularPrecioTotal()} MXN
                  </p>
                </Box>
              </>
            )}
          </Box>

          <FooterCart />
        </Box>
      </Drawer>
    </div>
  );
}

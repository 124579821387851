import React from "react";
import {
  Container,
  Grid,
  Typography,
  IconButton,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import TwitterIcon from "@mui/icons-material/Twitter";
import EmailIcon from "@mui/icons-material/Email";
import InstagramIcon from "@mui/icons-material/Instagram";
import Nabria from "../IMG/NA.webp";

const ComponentName = () => {
  const navigate = useNavigate();

  // Función para manejar la navegación al hacer clic en la imagen
  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <section style={{ paddingTop: "2rem", backgroundColor: "#000" }}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {/* Imagen y descripción - Usando xs para móviles */}
          <Grid item xs={12} sm={6} lg={4}>
            <img
              src={Nabria}
              alt="Nabria"
              style={{ height: "50px", cursor: "pointer" }}
              onClick={() => handleNavigation("/home")}
            />

            <Typography
              variant="body1" // Tamaño de texto más pequeño para móviles
              color="#fff"
              style={{ marginTop: "1rem" }}
            >
              Actualmente, Nabria se encuentra en desarrollo. Los modelos de
              pagos y envíos se establecerán en la página más adelante. las
              imagenes son temporales, se ajustaran mas adelante. Agradecemos su
              paciencia y les recomendamos estar atentos a futuras
              actualizaciones.
            </Typography>

            {/* Redes sociales con espaciado para móviles */}
            <Grid container spacing={1} style={{ marginTop: "1rem" }}>
              <Grid item>
                <IconButton
                  href="#"
                  aria-label="Twitter"
                  style={{ backgroundColor: "#333", color: "#fff" }}
                >
                  <TwitterIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  href="mailto:nabriamx@outlook.com" // Cambia "correo@ejemplo.com" por el correo deseado
                  aria-label="Enviar correo"
                  style={{ backgroundColor: "#333", color: "#fff" }}
                >
                  <EmailIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  href="https://www.instagram.com/nabria.mx/"
                  className="Instagram"
                  aria-label="Instagram"
                  style={{ backgroundColor: "#333", color: "#fff" }}
                >
                  <InstagramIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>

          {/* Enlaces "Help" */}
          {/*}
          <Grid item xs={12} sm={6} md={4}>
            <ul
              style={{
                listStyle: "none",
                paddingLeft: 1,
                marginTop: "3rem",
                display: "flex",
                gap: "2rem", // Espacio entre botones
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap", // Permitir que los elementos se ajusten en varias líneas en pantallas pequeñas
              }}
            >
              {[
                "Customer Support",
                "Delivery Details",
                "Terms & Conditions",
                "Privacy Policy",
              ].map((item) => (
                <li key={item} style={{ marginBottom: "1rem" }}>
                  <Button
                    href="#"
                    style={{ textTransform: "none", whiteSpace: "nowrap" }}
                  >
                    {item}
                  </Button>
                </li>
              ))}
            </ul>
          </Grid>
           */}
        </Grid>
        {/* Divider ajustado */}
        <Divider
          style={{ marginTop: "2rem", marginBottom: "2rem", color: "#fff" }}
        />

        <Typography variant="body2" align="center" color="#fff">
          © Copyright 2024, All Rights Reserved by Nabria.mx
        </Typography>
      </Container>
    </section>
  );
};

export default ComponentName;

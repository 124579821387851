import React from "react";
import "../IMG/Logo.webp";

const ProgressImage = () => {
  return (
    <div className="progress-container">
      <div className="progress-image" alt="Loading..."></div>
    </div>
  );
};

export default ProgressImage;

import React, { useEffect, useState } from "react";
import { Grid, Container, Typography, Box, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  AppBar,
  Toolbar,
  Button,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import "../css/Essentials.css";

const theme = createTheme({
  typography: {
    fontFamily: "MiFuente, sans-serif",
  },
  palette: {
    primary: { main: "#000" },
    secondary: { main: "#fff" },
  },
});

const ProductItem = ({ producto }) => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/sales", { state: { products: [producto] } });
  };

  const imagenPrincipal = producto.imagenes[0];
  const nombre = producto.nombre || "Producto sin nombre";
  const precio = producto.precio
    ? `${producto.precio} MXN`
    : "Precio no disponible";

  return (
    <Grid item xs={12} sm={6} md={3}>
      <Box
        onClick={handleClick}
        sx={{
          cursor: "pointer",
          "&:hover": { opacity: 0.8 },
        }}
      >
        <img
          src={imagenPrincipal}
          alt={nombre}
          loading="lazy"
          style={{
            width: "100%",
            height: isMobile ? "200px" : "300px",
            objectFit: "cover",
          }}
        />
        <Typography variant={isMobile ? "body1" : "h6"} sx={{ mt: 1 }}>
          {nombre}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ fontFamily: "Arial, sans-serif" }}
        >
          {precio}
        </Typography>
      </Box>
    </Grid>
  );
};

export default function AllProductsListComponent() {
  const [products, setProducts] = useState([]);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [error, setError] = useState(null);

  const apiUrl =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_API_URL_PROD + "/products"
      : process.env.REACT_APP_API_URL_DEV + "/products";

  console.log("API URL:", apiUrl);

  const fetchProductos = async () => {
    try {
      const response = await axios.get(apiUrl, { withCredentials: true });

      // Filtrar productos para mostrar solo uno por nombre
      const uniqueProducts = [];
      const productNames = new Set();

      response.data.forEach((product) => {
        if (!productNames.has(product.nombre)) {
          uniqueProducts.push(product);
          productNames.add(product.nombre); // Guardar el nombre para evitar duplicados
        }
      });

      setProducts(uniqueProducts);
    } catch (error) {
      console.error("Error al obtener los productos:", error);
      setError("No se pudieron obtener los productos. Inténtalo más tarde.");
    }
  };

  useEffect(() => {
    fetchProductos();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <AppBar
        sx={{
          position: "fixed",
          left: isMobile ? "15px" : 125,
          top: "5rem",
          width: isMobile ? "90%" : "29rem",
          backgroundColor: "transparent",
          boxShadow: "none",
        }}
      >
        <Toolbar sx={{ justifyContent: "center", flexDirection: "row" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "100%",
            }}
          >
            <Button
              color="inherit"
              sx={{
                color: " !important",
                "&:hover": { color: "gray" },
                fontSize: isMobile ? "0.8rem" : "1rem",
              }}
            ></Button>
          </Box>
        </Toolbar>
      </AppBar>

      <Container maxWidth="lg" sx={{ mt: isMobile ? 10 : 5 }}>
        <Box id="gorras" sx={{ mb: 1, pt: isMobile ? "100px" : "150px" }}>
          <Typography
            variant={isMobile ? "h5" : "h4"}
            component="h2"
            gutterBottom
          >
            NUESTROS PRODUCTOS
          </Typography>

          <Grid container spacing={3}>
            {Array.isArray(products) && products.length > 0 ? (
              products.map((producto, index) => (
                <ProductItem key={index} producto={producto} />
              ))
            ) : (
              <Typography>No hay productos disponibles</Typography>
            )}
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

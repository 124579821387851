import React, { useEffect, useState } from "react";
// eslint-disable-next-line
import navbar from "../components/Nabarall";

export default function Checkout() {
  const [isRegistered, setIsRegistered] = useState(false);
  const [discount, setDiscount] = useState(0);

  useEffect(() => {
    const registered = localStorage.getItem("isRegistered");
    setIsRegistered(registered ? true : false);

    if (registered) {
      setDiscount(0.1); // Aplica un 10% de descuento si está registrado
    }
  }, []);

  return (
    <div>
      <navbar />
      <h1>Checkout</h1>
      {isRegistered ? (
        <p>¡Bienvenido de nuevo! Se te ha aplicado un 10% de descuento.</p>
      ) : (
        <p>Estás comprando como invitado. No se aplican descuentos.</p>
      )}
      <p>
        Total:{" "}
        {discount > 0
          ? `Descuento aplicado: ${discount * 100}%`
          : "No se aplicó descuento."}
      </p>
    </div>
  );
}

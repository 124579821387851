import React from "react";
import { useLocation } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import SalesGallery from "../components/SalesGalleryMobil";
import CartSidebar from "../components/CartSidebar"; // Importamos el componente del carrito

function SalesMobile() {
  const location = useLocation();
  const products = location.state?.products || [];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        marginTop: "5rem",
      }}
    >
      {/* Navbar */}
      <div className="Navbar">
        <Navbar />
      </div>

      {/* Selector de cantidad fijo */}
      <div>
        <CartSidebar />
      </div>

      {/* Contenedor principal con la galería */}
      <div
        style={{
          flexGrow: 1, // Este contenedor crecerá para ocupar todo el espacio disponible
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "auto",
          padding: "2rem",
        }}
      >
        <div style={{ width: "100%", marginBottom: "-10rem" }}>
          {/* Galería de ventas */}
          <SalesGallery products={products} />
        </div>
      </div>

      {/* Footer siempre en la parte inferior */}
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default SalesMobile;

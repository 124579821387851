import React, { useState } from "react";
import NA from "../IMG/NAN.webp";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Sidebar from "../components/Sidebar";
import CustomButton from "../components/CustomButton";
import { useAuth } from "../Context/AuthContext";

export default function Register() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 600px)");
  // eslint-disable-next-line
  const { register } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false); // Estado de carga

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleEmailChange = (event) => {
    const inputEmail = event.target.value;
    setEmail(inputEmail);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailError(!emailRegex.test(inputEmail));
  };

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleChangeConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
    setError(event.target.value !== password);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleRegister = async () => {
    if (emailError || password !== confirmPassword) {
      return; // No procedemos si hay error en el email o las contraseñas no coinciden
    }

    setLoading(true); // Activar el estado de carga

    try {
      const response = await fetch("http://localhost:4000/api/register", {
        method: "POST",
        body: JSON.stringify({ email, password }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();

      if (data.success) {
        localStorage.setItem("isRegistered", true);
        localStorage.setItem("user", JSON.stringify(data.user));
        navigate("/Checkout"); // Redirigir a checkout en caso de éxito
      } else {
        console.log("Registro fallido", data.message);
        alert("Error al registrar: " + data.message); // Mensaje de error
      }
    } catch (error) {
      console.error("Error al registrar:", error);
      alert("Error al registrar. Por favor, intenta nuevamente."); // Mensaje de error
    } finally {
      setLoading(false); // Desactivar el estado de carga
    }
  };

  const handleGuestLogin = () => {
    navigate("/Checkout");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
        justifyContent: "center",
        marginTop: isMobile ? "-3rem" : "-4rem",
      }}
    >
      <Sidebar />
      <img
        src={NA}
        alt="NA"
        onClick={() => handleNavigation("/home")}
        style={{
          width: isMobile ? "60%" : "25%",
          height: "auto",
          cursor: "pointer",
          marginBottom: "20px",
        }}
      />
      <div
        className="textfield"
        style={{
          width: isMobile ? "80%" : "30%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TextField
          id="email"
          label="Email"
          variant="standard"
          fullWidth
          value={email}
          onChange={handleEmailChange}
          error={emailError}
          helperText={
            emailError ? "Introduce un correo electrónico válido" : ""
          }
          style={{ marginBottom: "20px" }}
        />
        <div className="name/surname" style={{ display: "flex" }}>
          <TextField
            id="Nombre"
            label="Nombre"
            variant="standard"
            fullWidth
            style={{ marginBottom: "20px" }}
          />
          <TextField
            id="Apellido"
            label="Apellido"
            variant="standard"
            fullWidth
            style={{ marginBottom: "20px" }}
          />
        </div>
        <TextField
          id="password"
          label="Password"
          variant="standard"
          type={showPassword ? "text" : "password"}
          onChange={handleChangePassword}
          fullWidth
          style={{ marginBottom: "20px" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          id="confirmPassword"
          label="Repetir Contraseña"
          variant="standard"
          type={showPassword ? "text" : "password"}
          onChange={handleChangeConfirmPassword}
          fullWidth
          style={{ marginBottom: "20px" }}
          error={error}
          helperText={error ? "Las contraseñas no coinciden" : ""}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <div>
          <CustomButton
            text={loading ? "Cargando..." : "Registrarse"} // Cambiar el texto durante la carga
            onClick={handleRegister}
            variant="contained"
            color="primary"
            sx={{
              borderRadius: "50px",
              padding: "10px",
              marginTop: isMobile ? "2rem" : "1rem",
              backgroundColor: "#000",
              width: isMobile ? "100%" : "100%",
              marginBottom: "2rem",
            }}
            disabled={loading} // Deshabilitar el botón durante la carga
          />
          <div>
            <a href="/Checkout" onClick={handleGuestLogin}>
              Ingresar como invitado
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

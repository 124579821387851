import React from "react";
import AllProductsList from "../components/AllProductsList";
import { Box } from "@mui/material";

import "../css/Essentials.css";
import Footer from "../components/Footer";
import Navbar from "../components/Nabarall";

function AllProducts() {
  return (
    <div>
      <div className="Navbar">
        <Navbar />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          marginTop: "-5rem",
          height: "100%",
        }}
      >
        <Box
          className="AllProducts"
          width={{
            xs: "100%",
            md: "100%",
          }}
        >
          <AllProductsList
            style={{
              width: "100%",
            }}
          />
        </Box>
      </div>
      <Box sx={{ mt: "10rem" }}>
        <Footer />
      </Box>
    </div>
  );
}

export default AllProducts;

import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import NA from "../IMG/NAN.webp";
import Sidebar from "./Sidebar";
import UserMenu from "./UserMenu";

const Navbar = () => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const navigate = useNavigate();

  // Función para manejar la navegación al hacer clic en la imagen
  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <AppBar
      style={{
        backgroundColor: "#fff",
        justifyContent: "center",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "left", // Centra el contenido horizontalmente
          marginLeft: isMobile ? "2rem" : "5rem",
          alignItems: "center", // Centra el contenido verticalmente
        }}
      >
        <Box
          component="img"
          src={NA}
          alt="NA"
          onClick={() => handleNavigation("/home")}
          sx={{
            width: isMobile ? "60%" : "11%", // Cambia el tamaño según el dispositivo
            height: "auto",
            cursor: "pointer",
          }}
        />
      </Toolbar>
      <Box
        sx={{
          position: isMobile ? "absolute" : "fixed",
          top: isMobile ? "15px" : "10px", // Ajuste de la posición superior
          left: isMobile ? "1px" : "-60px", // Ajuste de la posición izquierda
          zIndex: 2, // Por encima de la imagen
        }}
      >
        <Sidebar
          iconSize={isMobile ? "large" : "extra-large"} // Ajuste del tamaño del icono
        />
      </Box>
      {/* Aquí está el UserMenu ajustado */}
      <Box
        sx={{
          position: "absolute",
          right: isMobile ? "10px" : "50px", // Ajuste de la posición derecha
          top: "50%", // Centra verticalmente en relación al AppBar
          transform: "translateY(-50%)",
        }}
      >
        <UserMenu style={{ with: "20px", height: "20px" }} />
      </Box>
    </AppBar>
  );
};

export default Navbar;

import React, { useState, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { isMobile } from "react-device-detect";
// eslint-disable-next-line
import { AuthProvider, useAuth } from "./assets/Context/AuthContext";
import Essentials from "./assets/pages/Essentials";
import AboutUS from "./assets/pages/US";
import Admin from "./assets/pages/Login";
import NOTFOUND from "./assets/pages/404";
import ProgressImage from "./assets/pages/ProgressImage";
import AllProducts from "./assets/pages/AllProducts";
import Sales from "./assets/pages/Sales";
import SalesMobile from "./assets/pages/SalesMobile";
import Register from "./assets/pages/Register";
import Checkout from "./assets/pages/Checkout";
import Mission from "./assets/pages/Mission";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Analytics } from "@vercel/analytics/react";
import { CarritoProvider } from "./assets/Context/CarritoContext";

const LazyHome = React.lazy(() => import("./assets/pages/home"));
const LazyDashboard = React.lazy(() => import("./assets/pages/Dashboard"));
const App = () => {
  const auth = useAuth();
  const user = auth ? auth.user : null;
  const [isAdmin, setIsAdmin] = useState(false);

  const login = (email, password) => {
    if (
      email.trim().toLowerCase() === "gtaglea@hotmail.com" &&
      password.trim() === "Alonso33"
    ) {
      setIsAdmin(true);
      return true;
    } else {
      return false;
    }
  };

  return (
    <CarritoProvider>
      <Router>
        <Suspense fallback={<ProgressImage isLoading={true} />}>
          <Routes>
            <Route path="/" element={<LazyHome />} />
            <Route path="/Home" element={<LazyHome />} />
            <Route path="/AboutUS" element={<AboutUS />} />
            <Route path="/OurMission" element={<Mission />} />
            <Route path="/AllProducts" element={<AllProducts />} />
            <Route path="/login" element={<Admin login={login} />} />
            <Route path="/Register" element={<Register />} />
            <Route
              path="/Checkout"
              element={user ? <Checkout /> : <Navigate to="/Register" />}
            />
            <Route
              path="/dashboard"
              element={isAdmin ? <LazyDashboard /> : <Navigate to="/login" />}
            />
            <Route
              path="/Sales"
              element={isMobile ? <SalesMobile /> : <Sales />}
            />
            <Route path="*" element={<NOTFOUND />} />
          </Routes>
        </Suspense>
        <SpeedInsights />
        <Analytics />
      </Router>
    </CarritoProvider>
  );
};

export default App;

import React from "react";
import {
  Grid,
  Container,
  Box,
  Typography,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom"; // Importar useNavigate
import useMediaQuery from "@mui/material/useMediaQuery";
import ProductItem from "./ProductItem"; // Asegúrate de que el componente ProductItem esté importado
import "../css/Essentials.css";

// Tema personalizado
const theme = createTheme({
  typography: {
    fontFamily: "MiFuente, sans-serif",
  },
  palette: {
    primary: { main: "#ffff" },
    secondary: { main: "#ffff" },
  },
});

export default function TiendaTematicaMUI() {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const navigate = useNavigate(); // Definir el hook useNavigate para redirigir

  const colecciones = [
    {
      id: "Emblem",
      tituloCorto: "Emblem",
      tituloCompleto: "Emblem",
      products: [
        {
          imagen:
            "https://res.cloudinary.com/dgpby6sho/image/upload/f_auto,q_auto/zbei4bog4hjdvuqglh2o",
          alt: "Emblem ",
          nombre: "Emblem ",
          precio: "$120",
          imagenes: [
            "https://res.cloudinary.com/dgpby6sho/image/upload/f_auto,q_auto/zbei4bog4hjdvuqglh2o",
            "https://res.cloudinary.com/dgpby6sho/image/upload/f_auto,q_auto/rmgndb19sk0imvwxsnrm",
          ],
        },
        {
          imagen:
            "https://res.cloudinary.com/dgpby6sho/image/upload/f_auto,q_auto/rmgndb19sk0imvwxsnrm",
          alt: "Emblem Blue Hat",
          nombre: "Emblem Blue Hat",
          precio: "$90",
          imagenes: [
            "https://res.cloudinary.com/dgpby6sho/image/upload/f_auto,q_auto/rmgndb19sk0imvwxsnrm",
            "https://res.cloudinary.com/dgpby6sho/image/upload/f_auto,q_auto/zbei4bog4hjdvuqglh2o",
          ],
        },
      ],
    },
    {
      id: "ICON",
      tituloCorto: isMobile ? "ICON" : "Icon",
      tituloCompleto: "ICON",
      products: [
        {
          imagen:
            "https://res.cloudinary.com/dgpby6sho/image/upload/f_auto,q_auto/fmhr3dvjbwtjspcnlxql",
          alt: "Emblem Model",
          nombre: "Emblem ",
          precio: "$500",
          imagenes: [
            "https://res.cloudinary.com/dgpby6sho/image/upload/f_auto,q_auto/fmhr3dvjbwtjspcnlxql",
            "https://res.cloudinary.com/dgpby6sho/image/upload/f_auto,q_auto/do6f2pkw7aphec7junrx",
          ],
        },
        {
          imagen:
            "https://res.cloudinary.com/dgpby6sho/image/upload/f_auto,q_auto/mtujxxtgfc9nmvnh2kjl",
          alt: "Emblem Model",
          nombre: "Emblem ",
          precio: "$500",
          imagenes: [
            "https://res.cloudinary.com/dgpby6sho/image/upload/f_auto,q_auto/mtujxxtgfc9nmvnh2kjl",
            "https://res.cloudinary.com/dgpby6sho/image/upload/f_auto,q_auto/u0ymyegdp0e41gndqru7",
          ],
        },
        {
          imagen:
            "https://res.cloudinary.com/dgpby6sho/image/upload/f_auto,q_auto/u0ymyegdp0e41gndqru7",
          alt: "Emblem Model",
          nombre: "Emblem ",
          precio: "$500",
          imagenes: [
            "https://res.cloudinary.com/dgpby6sho/image/upload/f_auto,q_auto/u0ymyegdp0e41gndqru7",
            "https://res.cloudinary.com/dgpby6sho/image/upload/f_auto,q_auto/do6f2pkw7aphec7junrx",
          ],
        },
      ],
    },
    // Otras colecciones...
  ];

  const handleProductClick = (producto) => {
    // Redirigir a /Sales y pasar el producto seleccionado con sus imágenes
    navigate("/Sales", { state: { products: [producto] } });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg" sx={{ mt: isMobile ? 10 : 20 }}>
        {colecciones.map((coleccion) => (
          <Box
            key={coleccion.id}
            id={coleccion.id}
            sx={{ mb: 1, pt: isMobile ? "100px" : "150px" }}
          >
            <Typography
              variant={isMobile ? "h5" : "h4"}
              component="h2"
              gutterBottom
            >
              {coleccion.tituloCompleto}
            </Typography>

            <Grid container spacing={3}>
              {coleccion.products.map((producto, index) => (
                <ProductItem
                  key={index}
                  imagen={producto.imagen}
                  alt={producto.alt}
                  nombre={producto.nombre}
                  precio={producto.precio}
                  onClick={() => handleProductClick(producto)} // Pasar el producto al hacer clic
                />
              ))}
            </Grid>
          </Box>
        ))}
      </Container>
    </ThemeProvider>
  );
}

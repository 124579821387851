import React, { useState, useEffect, useContext } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { CarritoContext } from "../Context/CarritoContext";
import Snackbar from "@mui/material/Snackbar";
import "../css/Fonts.css";

function SalesGallery({ products }) {
  const { agregarProducto } = useContext(CarritoContext);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [imagenPrincipal, setImagenPrincipal] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [baseColor, setBaseColor] = useState("");
  const [secondaryColor, setSecondaryColor] = useState("");
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [estilo, setEstilo] = useState(""); // Estado para el estilo seleccionado

  // Arreglo de colores base y secundarios
  const coloresClassic = [
    { nombre: "Arena", colorCode: "#DBD3C9" },
    { nombre: "Amarillo Claro", colorCode: "#E8D94C" },
    { nombre: "Azul Acero", colorCode: "#56798D" },
    { nombre: "Azul Cian", colorCode: "#83D6DC" },
    { nombre: "Azul Rey", colorCode: "#1E3D86" },
    { nombre: "Blanco", colorCode: "#FFFF" },
    { nombre: "Cafe", colorCode: "#4C3B31" },
    { nombre: "Cerry", colorCode: "#652325" },
    { nombre: "Cielo", colorCode: "#A6C0D9" },
    { nombre: "Fucsia", colorCode: "#ED509B" },
    { nombre: "Gris Claro", colorCode: "#C0B6B7" },
    { nombre: "Gris Obscuro", colorCode: "#605C59" },
    { nombre: "Kaky", colorCode: "#B5A78A" },
    { nombre: "Ladrillo", colorCode: "#BA6B41" },
    { nombre: "Mandarina", colorCode: "#FF5D25" },
    { nombre: "Mango", colorCode: "#E1A625" },
    { nombre: "Marino", colorCode: "#222F3F" },
    { nombre: "Morado", colorCode: "#4B3573" },
    { nombre: "Mostaza", colorCode: "#D2AA4A" },
    { nombre: "Naranja claro", colorCode: "#FE973F" },
    { nombre: "Negro", colorCode: "#000" },
    { nombre: "Rosa", colorCode: "#FEC7CA" },
    { nombre: "Rosa Coral", colorCode: "#D38882" },
    { nombre: "Rojo", colorCode: "#BB0820" },
    { nombre: "Turquesa", colorCode: "#03A8F4" },
    { nombre: "Verde Botella", colorCode: "#203629" },
    { nombre: "Verde Limon", colorCode: "#8DCF6E" },
    { nombre: "Verde Menta", colorCode: "#9CC79A" },
    { nombre: "Verde Musgo", colorCode: "#768763" },
    { nombre: "Verde Vandera", colorCode: "#2F9A4C" },
    { nombre: "Vino", colorCode: "#672737" },
  ];
  const coloresVintage = [
    { nombre: "Amarillo Claro", colorCode: "#E8D94C" },
    { nombre: "Azul Marino", colorCode: "#3F6073" },
    { nombre: "Cielo", colorCode: "#A6C0D9" },
    { nombre: "Kaky", colorCode: "#B5A78A" },
    { nombre: "Ladrillo", colorCode: "#BA6B41" },
    { nombre: "Mostaza", colorCode: "#D2AA4A" },
    { nombre: "Negro", colorCode: "#000" },
    { nombre: "Turquesa", colorCode: "#83D6DC" },
    { nombre: "Verde Olivo", colorCode: "##6A6D5C" },
    { nombre: "Verde Limon", colorCode: "#8DCF6E" },
    { nombre: "Vino", colorCode: "#672737" },
  ];

  // Inicializamos el producto seleccionado al cargar la página
  useEffect(() => {
    if (
      products.length > 0 &&
      products[0].imagenes &&
      products[0].imagenes.length > 0
    ) {
      setSelectedProduct(products[0]);
      setImagenPrincipal(products[0].imagenes[0]);
    }
  }, [products]);

  const handleImageClick = (imagen) => {
    setImagenPrincipal(imagen);
  };

  const handleQuantityChange = (event) => {
    const value = event.target.value;
    if (value > 0 && value <= 10) {
      setQuantity(value);
    } else {
      setErrorMessage("La cantidad debe estar entre 1 y 10.");
      setOpen(true);
    }
  };

  const handleBaseColorChange = (event) => {
    setBaseColor(event.target.value);
  };

  const handleSecondaryColorChange = (event) => {
    setSecondaryColor(event.target.value);
  };

  const handleEstiloChange = (event) => {
    setEstilo(event.target.value); // Actualizar el estilo seleccionado
    setBaseColor(""); // Reiniciar el color base
    setSecondaryColor(""); // Reiniciar el color secundario
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleAddToCart = () => {
    if (!quantity || !baseColor || !secondaryColor) {
      // Si alguna opción no está seleccionada, mostrar un error
      setErrorMessage("Por favor selecciona cantidad, color base y bordado");
      setOpen(true);
    } else if (selectedProduct) {
      // Si el precio ya es un número, úsalo directamente
      const price = selectedProduct.precio || 0;

      const productToAdd = {
        productId: selectedProduct.id,
        productName: selectedProduct.nombre,
        quantity: quantity,
        baseColor: baseColor,
        secondaryColor: secondaryColor,
        productPrice: price,
        productImage: imagenPrincipal,
        totalPrice: quantity * price,
      };

      agregarProducto(productToAdd);
      setErrorMessage(""); // Limpiar el mensaje de error
      handleClick();

      // Limpiar los campos una vez que se haya agregado al carrito
      setQuantity(1);
      setBaseColor("");
      setSecondaryColor("");
    }
  };
  if (!selectedProduct || !imagenPrincipal) {
    return <div>No hay imágenes disponibles</div>;
  }

  // Seleccionar colores según el estilo seleccionado
  const coloresDisponibles =
    estilo === "classic"
      ? coloresClassic
      : estilo === "vintage"
      ? coloresVintage
      : [];

  return (
    <div
      className="Font-Container"
      style={{ display: "flex", alignItems: "flex-start", marginTop: "9rem" }}
    >
      {/* Contenedor de miniaturas */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          marginRight: "2rem",
          marginTop: "5rem",
        }}
      >
        {selectedProduct.imagenes.map((imagen, index) => (
          <img
            key={index}
            src={imagen}
            alt={`${selectedProduct.nombre} - ${index + 1}`}
            style={{
              width: "80px",
              objectFit: "cover",
              cursor: "pointer",
              border: imagen === imagenPrincipal ? "2px solid #00ff2b" : "none",
            }}
            onClick={() => handleImageClick(imagen)}
          />
        ))}
      </div>

      {/* Contenedor principal */}
      <div style={{ display: "flex", flexGrow: 1 }}>
        <div style={{ marginRight: "3rem" }}>
          <img
            src={imagenPrincipal}
            alt={selectedProduct.nombre}
            style={{
              width: "30rem",
              height: "35rem",
              objectFit: "cover",
            }}
          />
        </div>

        <div
          style={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            marginTop: "5rem",
            alignItems: "flex-start",
          }}
        >
          {/* Nombre y precio del producto */}
          <div
            style={{
              marginBottom: "5rem",
              fontSize: "1.5rem",
            }}
          >
            <span className="Font-container">{selectedProduct.nombre}</span> -{" "}
            <span style={{ fontFamily: "arial" }}>
              {selectedProduct.precio}
            </span>
          </div>

          {/* Selección de Estilo */}
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel id="estilo-select-label">Estilo</InputLabel>
            <Select
              labelId="estilo-select-label"
              id="estilo-select"
              value={estilo}
              label="Estilo"
              sx={{ borderRadius: "18px", width: "20rem" }}
              onChange={handleEstiloChange}
            >
              <MenuItem value="classic">Classic</MenuItem>
              <MenuItem value="vintage">Vintage</MenuItem>
            </Select>
          </FormControl>

          {/* Selección de Cantidad */}
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel id="quantity-select-label">Cantidad</InputLabel>
            <Select
              labelId="quantity-select-label"
              id="quantity-select"
              value={quantity}
              label="Cantidad"
              sx={{ borderRadius: "18px", width: "20rem" }}
              onChange={handleQuantityChange}
            >
              {[...Array(10).keys()].map((x) => (
                <MenuItem key={x + 1} value={x + 1}>
                  {x + 1}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Selección de Color Base */}
          {estilo && (
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel id="base-color-select-label">Colores Base</InputLabel>
              <Select
                labelId="base-color-select-label"
                id="base-color-select"
                value={baseColor}
                label="Colores Base"
                sx={{ borderRadius: "18px", width: "20rem" }}
                onChange={handleBaseColorChange}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                }}
              >
                {coloresDisponibles.map((color, index) => (
                  <MenuItem key={index} value={color.nombre}>
                    <Box
                      sx={{
                        width: 20,
                        height: 20,
                        backgroundColor: color.colorCode,
                        display: "inline-block",
                        marginRight: "10px",
                        borderRadius: "50px",
                      }}
                    />
                    {color.nombre}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {/* Selección de Color Secundario */}
          {estilo && (
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel id="secondary-color-select-label">
                Colores Secundarios
              </InputLabel>
              <Select
                labelId="secondary-color-select-label"
                id="secondary-color-select"
                value={secondaryColor}
                label="Colores Secundarios"
                sx={{ borderRadius: "18px", width: "20rem" }}
                onChange={handleSecondaryColorChange}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                }}
              >
                {coloresDisponibles.map((color, index) => (
                  <MenuItem key={index} value={color.nombre}>
                    <Box
                      sx={{
                        width: 20,
                        height: 20,
                        backgroundColor: color.colorCode,
                        display: "inline-block",
                        marginRight: "10px",
                        borderRadius: "50px",
                      }}
                    />
                    {color.nombre}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {/* Botón para añadir al carrito */}
          <div className="Cart-container">
            <Button
              variant="contained"
              color="primary"
              sx={{
                marginTop: 2,
                borderRadius: "10rem",
                width: "20.5rem",
                backgroundColor: "black",
              }}
              onClick={handleAddToCart}
            >
              Añadir al carrito
            </Button>
            <Snackbar
              open={open}
              autoHideDuration={2000}
              onClose={handleClose}
              message={errorMessage || "Agregado al carrito"}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              sx={{
                position: "absolute",
                zIndex: 9999,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SalesGallery;

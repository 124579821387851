import React, { useEffect, useState, useContext } from "react";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CarritoContext } from "../Context/CarritoContext";

const FooterCart = () => {
  const { carrito } = useContext(CarritoContext); // Obtenemos el carrito del contexto
  const [precioTotal, setPrecioTotal] = useState("0.00"); // Estado para almacenar el total
  const navigate = useNavigate(); // Para navegar entre rutas

  // Función para hacer la llamada al backend y calcular el total
  const calcularPrecioTotalBackend = async () => {
    // Verificar que el carrito tenga datos válidos
    console.log("Contenido del carrito:", carrito); // Para depurar

    // Si el carrito está vacío o algún valor no es válido, no hacer la llamada
    if (
      carrito.length === 0 ||
      carrito.some(
        (producto) => isNaN(producto.precio) || isNaN(producto.cantidad)
      )
    ) {
      setPrecioTotal("0.00");
      return;
    }

    try {
      const response = await fetch(
        "http://localhost:4000/api/calcular-precio-total",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ carrito }), // Enviar el carrito al backend
        }
      );

      const data = await response.json();

      if (response.ok) {
        setPrecioTotal(data.precioTotal); // Actualizar el total en el estado
      } else {
        console.error("Error en el cálculo de precio", data.error);
        setPrecioTotal("0.00"); // Establecer a 0.00 en caso de error
      }
    } catch (error) {
      console.error("Error en la conexión con el backend", error);
      setPrecioTotal("0.00"); // Establecer a 0.00 en caso de error
    }
  };

  // Efecto para calcular el precio cada vez que cambie el carrito
  useEffect(() => {
    if (carrito.length > 0) {
      calcularPrecioTotalBackend(); // Solo llama a la API si hay products en el carrito
    } else {
      setPrecioTotal("0.00"); // Si no hay products, el total es 0
    }
    // eslint-disable-next-line
  }, [carrito]); // Dependencia de 'carrito'

  // Función para manejar la navegación a la página de Checkout
  const handleCheckout = () => {
    navigate("/Checkout"); // Redirigir a la página de Checkout
  };

  return (
    <section
      style={{
        marginBottom: "-1rem",
        paddingBottom: "10rem",
        backgroundColor: "rgb(224, 219, 218,.4)",
        marginLeft: "-1rem",
        width: "110%",
      }}
    >
      {/* Mostrar el total arriba del botón de checkout */}
      <Typography
        variant="h6"
        align="center"
        style={{ marginBottom: "1rem", color: "#000" }}
      >
        Total a pagar:
        <span
          style={{
            fontFamily: "Arial, sans-serif",
            fontSize: "24px",
            fontWeight: "bold",
            marginLeft: "5px",
          }}
        >
          ${precioTotal} MXN
        </span>
      </Typography>

      {/* Botón para redirigir al Checkout */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleCheckout}
        style={{
          backgroundColor: "#000",
          position: "relative",
          top: "11vh",
          left: "-9rem",
          width: "90%",
          borderRadius: "10rem",
          marginLeft: "10rem",
          zIndex: "9999",
        }}
      >
        Ir a Checkout
      </Button>
    </section>
  );
};

export default FooterCart;

// AboutUs.jsx
import React from "react";
import { Container, Typography, Box, Card, CardContent } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { styled } from "@mui/system";
import backgroundImage from "../IMG/nbr1.webp"; // Añade una imagen de fondo si deseas

const theme = createTheme({
  palette: {
    primary: { main: "#000" },
    secondary: { main: "#479f19" },
    background: { default: "#f5f5f5" },
  },
  typography: {
    fontFamily: "Arial, sans-serif",
    h2: {
      fontWeight: 700,
      color: "#333",
    },
    body1: {
      fontSize: "1.2rem",
      lineHeight: 1.8,
    },
  },
});

const BackgroundBox = styled(Box)({
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  minHeight: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "2rem",
});

const AboutUs = () => {
  return (
    <ThemeProvider theme={theme}>
      <BackgroundBox>
        <Container maxWidth="md">
          <Card
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.9)",
              boxShadow: 4,
              borderRadius: 2,
            }}
          >
            <CardContent>
              <Typography variant="h2" align="center" gutterBottom>
                Acerca de Nabria
              </Typography>
              <Typography variant="body1" paragraph>
                En <strong>Nabria</strong>, creemos en la moda que trasciende
                tendencias y se convierte en un reflejo de autenticidad. Nos
                dedicamos a crear prendas con un enfoque en la calidad premium y
                el detalle, fusionando el arte del bordado con materiales
                excepcionales para ofrecer una experiencia de estilo
                verdaderamente única.
              </Typography>
              <Typography variant="body1" paragraph>
                Diseñamos para todos, abrazando la diversidad y ofreciendo
                tallas inclusivas que celebran la individualidad de cada
                persona. Nuestro estilo es atemporal: cada pieza está pensada
                para perdurar y ser parte de tu guardarropa a largo plazo,
                aportando distinción y comodidad a tu día a día.
              </Typography>
              <Typography variant="body1" paragraph>
                En <strong>Nabria</strong>, cada prenda es más que moda; es una
                expresión de calidad, cuidado y autenticidad, hecha para
                acompañarte en cada momento.
              </Typography>
            </CardContent>
          </Card>
        </Container>
      </BackgroundBox>
    </ThemeProvider>
  );
};

export default AboutUs;

import React from "react";
import { useLocation } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import SalesGallery from "../components/Salesgalery";
import CartSidebar from "../components/CartSidebar";
import { useNavigate } from "react-router-dom";

function Sales() {
  const location = useLocation();
  const products = location.state?.products || [];

  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh", // Ocupa toda la altura del viewport
      }}
    >
      {/* Navbar */}
      <div className="Navbar">
        <Navbar />
      </div>

      {/* Carrito de compras en Sidebar */}
      <div style={{ marginTop: "4rem" }}>
        <CartSidebar />
      </div>
      <div>
        <button
          style={{
            marginTop: "2rem",
            marginLeft: "1rem",
            borderRadius: "20rem",
            width: "8rem",
            height: "2rem",
            color: "black",
            backgroundColor: "#fff",
            cursor: "pointer",
          }}
          onClick={() => navigate(-1)}
        >
          Regresar
        </button>
      </div>

      {/* Contenedor principal con la galería */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "auto",
          marginLeft: "14rem",
          marginTop: "-10",
          padding: "2rem",
          flex: 1,
        }}
      >
        <div
          style={{
            marginTop: "-8rem",
            width: "45%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "6rem",
          }}
        >
          {/* Galería de ventas */}
          <SalesGallery products={products} />
        </div>
      </div>

      {/* Footer */}
      <div style={{ marginTop: "auto" }}>
        <Footer />
      </div>
    </div>
  );
}
export default Sales;

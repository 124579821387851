import React, { useState, useEffect, useContext, useRef } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import { CarritoContext } from "../Context/CarritoContext";
import "../css/Cart.css"; // Asegúrate de que tu CSS esté correctamente importado

function SalesGalleryMobile({ products }) {
  const { agregarProducto } = useContext(CarritoContext); // Usamos el contexto del carrito
  const [selectedProduct, setSelectedProduct] = useState(null); // Iniciamos como null
  const [imagenPrincipal, setImagenPrincipal] = useState(null); // Imagen principal inicializada como null
  const [quantity, setQuantity] = useState(1); // Estado para la cantidad
  const [baseColor, setBaseColor] = useState(""); // Estado para el color base
  const [secondaryColor, setSecondaryColor] = useState(""); // Estado para el color secundario
  const [open, setOpen] = useState(false); // Estado para manejar la visibilidad del Snackbar
  const buttonRef = useRef(null); // Ref para el botón
  // eslint-disable-next-line
  const [buttonPosition, setButtonPosition] = useState({ top: 0, left: 0 });

  // Arreglo de colores base y secundarios
  const colores = [
    { nombre: "Arena", colorCode: "#DBD3C9" },
    { nombre: "Amarillo Claro", colorCode: "#E8D94C" },
    { nombre: "Azul Acero", colorCode: "#56798D" },
    { nombre: "Azul Cian", colorCode: "#83D6DC" },
    { nombre: "Azul Rey", colorCode: "#1E3D86" },
    { nombre: "Blanco", colorCode: "#FFFF" },
    { nombre: "Cafe", colorCode: "#4C3B31" },
    { nombre: "Cerry", colorCode: "#652325" },
    { nombre: "Cielo", colorCode: "#A6C0D9" },
    { nombre: "Fucsia", colorCode: "#ED509B" },
    { nombre: "Gris Claro", colorCode: "#C0B6B7" },
    { nombre: "Gris Obscuro", colorCode: "#605C59" },
    { nombre: "Kaky", colorCode: "#B5A78A" },
    { nombre: "Ladrillo", colorCode: "#BA6B41" },
    { nombre: "Mandarina", colorCode: "#FF5D25" },
    { nombre: "Mango", colorCode: "#E1A625" },
    { nombre: "Marino", colorCode: "#222F3F" },
    { nombre: "Morado", colorCode: "#4B3573" },
    { nombre: "Mostaza", colorCode: "#D2AA4A" },
    { nombre: "Naranja claro", colorCode: "#FE973F" },
    { nombre: "Negro", colorCode: "#000" },
    { nombre: "Rosa", colorCode: "#FEC7CA" },
    { nombre: "Rosa Coral", colorCode: "#D38882" },
    { nombre: "Rojo", colorCode: "#BB0820" },
    { nombre: "Turquesa", colorCode: "#03A8F4" },
    { nombre: "Verde Botella", colorCode: "#203629" },
    { nombre: "Verde Limon", colorCode: "#8DCF6E" },
    { nombre: "Verde Menta", colorCode: "#9CC79A" },
    { nombre: "Verde Musgo", colorCode: "#768763" },
    { nombre: "Verde Vandera", colorCode: "#2F9A4C" },
    { nombre: "Vino", colorCode: "#672737" },
  ];

  // UseEffect para actualizar el producto seleccionado si la lista de products cambia
  useEffect(() => {
    if (
      products.length > 0 &&
      products[0].imagenes &&
      products[0].imagenes.length > 0
    ) {
      setSelectedProduct(products[0]); // Actualizamos con el primer producto si hay products y imágenes
      setImagenPrincipal(products[0].imagenes[0]); // Imagen principal es la primera imagen
    }
  }, [products]);

  // Obtener la posición del botón en la pantalla cuando se monta el componente
  useEffect(() => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setButtonPosition({ top: rect.bottom, left: rect.left });
    }
  }, [buttonRef]);

  // Función para cambiar la imagen principal cuando se hace clic en una miniatura
  const handleImageClick = (imagen) => {
    setImagenPrincipal(imagen); // Actualizamos la imagen principal
  };

  // Función para manejar cambios en la cantidad
  const handleQuantityChange = (event) => {
    setQuantity(event.target.value);
  };

  // Función para manejar cambios en el color base
  const handleBaseColorChange = (event) => {
    setBaseColor(event.target.value);
  };

  // Función para manejar cambios en el color secundario
  const handleSecondaryColorChange = (event) => {
    setSecondaryColor(event.target.value);
  };

  // Función para añadir el producto al carrito
  const handleAddToCart = () => {
    if (selectedProduct) {
      const price = Number(selectedProduct.precio.replace(/[^\d.-]/g, "")) || 0; // Asegurarnos de que el precio sea un número válido

      const productToAdd = {
        productId: selectedProduct.id,
        productName: selectedProduct.nombre,
        quantity: quantity,
        baseColor: baseColor,
        secondaryColor: secondaryColor,
        productPrice: price,
        productImage: imagenPrincipal,
        totalPrice: quantity * price, // Precio total (cantidad * precio unitario)
      };

      agregarProducto(productToAdd); // Añadimos el producto al carrito
      setOpen(true); // Mostrar el Snackbar
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false); // Cerrar el Snackbar
  };

  // Si no hay products o no hay imagen principal disponible, mostramos un mensaje o una pantalla vacía
  if (!selectedProduct || !imagenPrincipal) {
    return <div>No hay imágenes disponibles</div>;
  }

  return (
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "1rem",
        gap: "1rem",
      }}
    >
      {/* Imagen principal */}
      <img
        src={imagenPrincipal}
        alt={selectedProduct.nombre}
        style={{
          width: "100%",
          maxWidth: "3000px",
          height: "auto",
          objectFit: "cover",
          borderRadius: "20px",
        }}
      />

      {/* Contenedor de miniaturas debajo de la imagen principal */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "1rem",
          marginTop: "1rem",
          marginBottom: "2rem",
        }}
      >
        {selectedProduct.imagenes.map((imagen, index) => (
          <img
            key={index}
            src={imagen}
            alt={`${selectedProduct.nombre} - ${index + 1}`}
            style={{
              width: "60px",
              height: "60px",
              objectFit: "cover",
              cursor: "pointer",
              border: imagen === imagenPrincipal ? "2px solid #00ff2b" : "none", // Resaltar la imagen seleccionada
              borderRadius: "5px", // Bordes redondeados
            }}
            onClick={() => handleImageClick(imagen)} // Cambiar la imagen principal al hacer clic
          />
        ))}
      </Box>

      {/* Nombre del producto */}
      <div
        style={{
          marginBottom: "5rem",
          fontSize: "1.5rem",
        }}
      >
        <span className="Font-container">{selectedProduct.nombre}</span> -{" "}
        <span style={{ fontFamily: "arial" }}>{selectedProduct.precio}</span>
      </div>

      {/* Selección de Cantidad */}
      <FormControl fullWidth sx={{ marginBottom: -1, marginTop: "-2rem" }}>
        <InputLabel id="quantity-select-label">Cantidad</InputLabel>
        <Select
          labelId="quantity-select-label"
          id="quantity-select"
          value={quantity}
          label="Cantidad"
          sx={{ borderRadius: "18px", width: "100%", maxWidth: "20rem" }}
          onChange={handleQuantityChange}
        >
          {[...Array(10).keys()].map((x) => (
            <MenuItem key={x + 1} value={x + 1}>
              {x + 1}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Selección de Color Base */}
      <FormControl
        fullWidth
        sx={{
          marginBottom: 1,
          width: "100%", // Asegura que ambos FormControl tengan el mismo ancho
          maxWidth: "20rem", // Limita el ancho máximo para móviles
          marginTop: "1rem",
        }}
      >
        <InputLabel id="base-color-select-label">Color</InputLabel>
        <Select
          labelId="base-color-select-label"
          id="base-color-select"
          value={baseColor}
          label="Color"
          sx={{
            borderRadius: "18px",
          }}
          onChange={handleBaseColorChange}
          MenuProps={{
            PaperProps: {
              sx: {
                fontFamily: "MiFuente, sans-serif",
              },
            },
          }}
        >
          {colores.map((color, index) => (
            <MenuItem key={index} value={color.nombre}>
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  backgroundColor: color.colorCode,
                  display: "inline-block",
                  marginRight: "10px",
                }}
              />
              {color.nombre}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl
        fullWidth
        sx={{
          marginBottom: 2,
          width: "100%",
          maxWidth: "20rem",
          marginTop: "1rem", // Añade el mismo margen superior para asegurar uniformidad
        }}
      >
        <InputLabel id="secondary-color-select-label">Bordado</InputLabel>
        <Select
          labelId="secondary-color-select-label"
          id="secondary-color-select"
          value={secondaryColor}
          label="Colores del bordado"
          sx={{
            borderRadius: "18px",
          }}
          onChange={handleSecondaryColorChange}
          MenuProps={{
            PaperProps: {
              sx: {
                fontFamily: "MiFuente, sans-serif",
              },
            },
          }}
        >
          {colores.map((color, index) => (
            <MenuItem key={index} value={color.nombre}>
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  backgroundColor: color.colorCode,
                  display: "inline-block",
                  marginRight: "10px",
                }}
              />
              {color.nombre}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Botón para añadir al carrito */}
      <div className="Cart-container">
        <Button
          variant="contained"
          color="primary"
          ref={buttonRef} // Asignamos el ref al botón
          sx={{
            marginTop: 2,
            borderRadius: "10rem",
            width: "20.5rem",
            backgroundColor: "black",
          }}
          onClick={handleAddToCart}
        >
          Añadir al carrito
        </Button>
        {/* Snackbar con posición dinámica debajo del botón */}
        <Snackbar
          open={open}
          autoHideDuration={2000}
          onClose={handleClose}
          message="Agregado al carrito"
          anchorOrigin={{
            vertical: "500%",
            horizontal: "left",
          }}
          sx={{
            position: "absolute",
            zIndex: 9999,
          }}
        />
      </div>
    </div>
  );
}

export default SalesGalleryMobile;

import React, { useState } from "react";
import NA from "../IMG/NAN.webp";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Sidebar from "../components/Sidebar";
import CustomButton from "../components/CustomButton";

export default function Register({ login }) {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  //eslint-disable-next-line
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleRegister(event);
    }
  };
  //eslint-disable-next-line
  const token = localStorage.getItem("token");

  const handleRegister = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch("http://localhost:4000/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();
      if (response.ok) {
        // Guardar token en localStorage
        localStorage.setItem("token", data.token);

        navigate(-1);
      } else {
        setErrorMessage(data.message);
      }
    } catch (error) {
      console.error("Error durante el inicio de sesion", error);
      setErrorMessage("Error al iniciar sesión");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
        justifyContent: "center",
        marginTop: "-5rem",
      }}
    >
      <Sidebar />
      <img
        src={NA}
        alt="NA"
        onClick={() => handleNavigation("/home")}
        style={{
          width: isMobile ? "60%" : "25%",
          height: "auto",
          cursor: "pointer",
          marginBottom: "20px",
        }}
      />
      <div
        className="textfield"
        style={{
          width: isMobile ? "80%" : "30%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Mostrar error de login */}
        {errorMessage && (
          <div style={{ color: "red", marginBottom: "20px" }}>
            {errorMessage}
          </div>
        )}

        <TextField
          id="email"
          label="Email"
          variant="standard"
          fullWidth
          style={{ marginBottom: "20px" }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyDown={handleKeyDown}
        />

        <TextField
          id="contraseña"
          label="Contraseña"
          variant="standard"
          type={showPassword ? "text" : "password"}
          onChange={handleChangePassword}
          fullWidth
          style={{ marginBottom: "20px" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onKeyDown={handleKeyDown}
        />

        <div
          className="Boton"
          style={{
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* Botón de registro con lógica de autenticación */}
          <CustomButton
            text="Ingresar"
            onClick={handleRegister}
            variant="contained"
            color="primary"
            sx={{
              borderRadius: "50px",
              padding: "10px 20px",
              backgroundColor: "#000",
              width: isMobile ? "100%" : "100%",
            }} // Estilos personalizados con sx
          />
        </div>
      </div>
    </div>
  );
}

import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate, useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import useMediaQuery from "@mui/material/useMediaQuery";
import NA from "../IMG/NA.webp";
import "../css/Fonts.css";

export default function TemporaryDrawer() {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const location = useLocation();

  const whiteSections = ["/home", "/", "/sales", "/Sales"];
  const isWhiteSection = whiteSections.includes(location.pathname);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleNavigation = (path) => {
    navigate(path);
    setOpen(false);
  };

  const DrawerList = (
    <Box
      sx={{
        width: isMobile ? "75vw" : "40vh",
        height: isMobile ? "100vh" : "55vh",
        bgcolor: "black",
        color: "white",
        textAlign: "center",
        marginTop: 0,
        paddingTop: "1rem",
        fontFamily: "'MiFuente', sans-serif", // Aquí forzamos la aplicación de la fuente en todo el Drawer
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
    >
      <Box>
        <img src={NA} alt="Menu" style={{ width: "80%", height: "auto" }} />
      </Box>
      <List>
        <ListItem
          disablePadding
          sx={{ width: "100%", marginBottom: 0, minHeight: 64 }}
        >
          <ListItemButton
            sx={{ width: "100%", justifyContent: "center", padding: 0 }}
            onClick={() => handleNavigation("/home")}
          >
            <ListItemText
              primary="Home"
              sx={{
                textAlign: "center",
                fontSize: "1.2rem",
                fontFamily: "'MiFuente', sans-serif", // Forzamos la fuente en este item
              }}
            />
          </ListItemButton>
        </ListItem>
        <Divider sx={{ bgcolor: "white" }} />

        <ListItem
          disablePadding
          sx={{ width: "100%", marginBottom: 0, minHeight: 64 }}
        >
          <ListItemButton
            sx={{ width: "100%", justifyContent: "center", padding: 0 }}
            onClick={() => handleNavigation("/allproducts")}
          >
            <ListItemText
              primary="All Products"
              sx={{
                textAlign: "center",
                fontSize: "1.2rem",
                fontFamily: "'MiFuente', sans-serif", // Forzamos la fuente
              }}
            />
          </ListItemButton>
        </ListItem>

        <Divider sx={{ bgcolor: "white" }} />

        <ListItem
          disablePadding
          sx={{ width: "100%", marginBottom: 0, minHeight: 64 }}
        >
          <ListItemButton
            sx={{ width: "100%", justifyContent: "center", padding: 0 }}
            onClick={() => handleNavigation("/AboutUS")}
          >
            <ListItemText
              primary="About US"
              sx={{
                textAlign: "center",
                fontSize: "1.2rem",
                fontFamily: "'MiFuente', sans-serif",
              }}
            />
          </ListItemButton>
        </ListItem>

        <Divider sx={{ bgcolor: "white" }} />

        <ListItem
          disablePadding
          sx={{ width: "100%", marginBottom: 0, minHeight: 64 }}
        >
          <ListItemButton
            sx={{ width: "100%", justifyContent: "center", padding: 0 }}
            onClick={() => handleNavigation("/OurMission")}
          >
            <ListItemText
              primary="Our Mission"
              sx={{
                textAlign: "center",
                fontSize: "1.2rem",
                fontFamily: "'MiFuente', sans-serif", // Forzamos la fuente
              }}
            />
          </ListItemButton>
        </ListItem>

        <Divider sx={{ bgcolor: "white" }} />
      </List>
    </Box>
  );

  return (
    <div>
      <Button
        onClick={toggleDrawer(true)}
        sx={{
          color: "white",
          "&:hover": {
            backgroundColor: "transparent",
          },
          position: "absolute",
          top: 0,
          left: isMobile ? 0 : "100px",
          zIndex: 5,
          fontSize: isMobile ? "2rem" : "3rem",
        }}
      >
        <MenuIcon
          fontSize="inherit"
          sx={{
            fontSize: isMobile ? "30px" : "39px",
            color: isWhiteSection ? "white" : "black",
          }}
        />
      </Button>

      <Drawer
        anchor={isMobile ? "left" : "top"}
        open={open}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            backgroundColor: "black",
            color: "white",
            position: "fixed",
            top: isMobile ? 0 : "0px",
            left: isMobile ? 0 : "100px",
            right: isMobile ? 0 : "auto",
            margin: isMobile ? 0 : "auto",
            width: isMobile ? "80vw" : "auto",
            height: isMobile ? "100vh" : "auto",
            zIndex: 10,
          },
        }}
      >
        {DrawerList}
      </Drawer>
    </div>
  );
}
